<template>
	<div class="multiselect-play">
		<div
			v-if="label && enableSelectAll"
			class="d-flex align-items-center justify-content-between"
		>
			<label :for="id">{{ inputLabel ?? $t("multiselect.label") }}</label>
			<p
				class="toggle-select-all"
				@click="toggleSelectAll()"
			>
				{{ isAllSelected ? $t("multiselect.unselect_all") : $t("multiselect.select_all") }}
			</p>
		</div>

		<Multiselect
			:id="id"
			v-model="selected"
			:options="options"
			:multiple="multiple"
			:close-on-select="closeOnSelect"
			:clear-on-select="clearOnSelect"
			:preserve-search="preserveSearch"
			:placeholder="placeholder ?? $t('multiselect.placeholder')"
			:label="label"
			:track-by="trackBy"
			:preselect-first="preselectFirst"
			:showLabels="showLabels"
			:limit="limit"
			:limitText="count => `+${count}`"
		>
			<template #option="props">
				<div class="d-flex align-items-center">
					<input
						type="checkbox"
						:checked="isSelected(props.option)"
						class="mr-2"
					/>
					<div class="option__desc">
						<span class="option__title">{{ props.option[label] }}</span>
					</div>
				</div>
			</template>

			<span slot="noResult">{{ noResult ?? $t("multiselect.not_found") }}</span>

			<span slot="noOptions">{{ noOptions ?? $t("multiselect.no_options") }}</span>
		</Multiselect>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
	name: "DesignSystemMultiselect",
	components: { Multiselect },
	props: {
		value: {
			type: [Array, Object, String, Number],
			default: null,
		},
		enableSelectAll: {
			type: Boolean,
			default: false,
		},
		limit: {
			type: Number,
			default: 15,
		},
		inputLabel: {
			type: String,
		},
		options: {
			type: Array,
			required: true,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		closeOnSelect: {
			type: Boolean,
			default: true,
		},
		clearOnSelect: {
			type: Boolean,
			default: true,
		},
		preserveSearch: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
		},
		noResult: {
			type: String,
		},
		noOptions: {
			type: String,
		},
		label: {
			type: String,
			default: "name",
		},
		trackBy: {
			type: String,
			default: "name",
		},
		preselectFirst: {
			type: Boolean,
			default: false,
		},
		showLabels: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			id: `multiselect-play-${this._uid}`,
			selected: this.value,
		};
	},
	watch: {
		selected(newVal) {
			this.$emit("input", newVal);
		},
	},
	computed: {
		isAllSelected() {
			return this.options?.length === this.selected?.length;
		},
	},
	methods: {
		isSelected(option) {
			return this.multiple ? this.selected?.includes(option) : this.selected === option;
		},
		toggleSelectAll() {
			if (this.isAllSelected) {
				this.selected = [];
			} else {
				this.selected = this.options;
			}
		},
	},
};
</script>

<style lang="scss">
.multiselect-play {
	label {
		font-size: 16px;
		font-weight: 600;
		color: var(--fontcolor);
	}

	.toggle-select-all {
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: var(--multiselect-toggle-btn-color);
		text-decoration: underline;
		font-size: 12px;
		margin: 0;
	}

	.multiselect {
		&__tags {
			background-color: var(--multiselect-bg) !important;
			border: 1px solid var(--multiselect-border) !important;
			overflow: auto;
			max-height: 200px;
			height: auto !important;
		}

		&__strong {
			margin: 0;
			color: white !important;
			background-color: #2a63ab !important;
			border: 1px solid white !important;
			border-radius: 1rem;
			font-size: 12px;
			padding: 0 6px;
			margin: 4px 4px 0 0;
		}

		&__placeholder,
		&__input::placeholder {
			color: var(--multiselect-placeholder) !important;
			font-weight: 600;
			padding: 0;
			margin: 0;
		}

		&__input {
			margin: 0;

			&:focus {
				margin: 4px 0;
			}
		}

		&__tag {
			background-color: var(--multiselect-badge-bg) !important;
			border: 1px solid var(--multiselect-border) !important;
			font-weight: 500;
			border-radius: 1rem;
			font-size: 12px;
			padding: 4px 6px;
			margin: 4px 4px 0 0;

			span {
				margin-right: 1rem;
				background-color: var(--multiselect-badge-bg) !important;
				color: var(--multiselect-badge-color) !important;
			}

			&-icon {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&-icon:hover {
				background-color: transparent !important;
			}

			&-icon:after {
				color: var(--multiselect-badge-border) !important;
				content: "×";
				font-size: 0.875rem;
			}
		}

		&__option {
			color: var(--multiselect-item-color) !important;
			font-weight: 400 !important;
			font-size: 14px !important;

			&--selected {
				background-color: var(--multiselect-item-selected) !important;
				color: var(--fontcolor) !important;
			}

			&--highlight {
				background-color: var(--multiselect-item-hover-bg) !important;
			}
		}

		&__select:before {
			position: relative;
			right: 0;
			top: 65%;
			color: var(--multiselect-border);
			margin-top: 0.25rem;
			border-style: solid;
			border-width: 0.3125rem 0.3125rem 0 0.3125rem;
			border-color: var(--multiselect-border) transparent transparent transparent;
			content: "";
		}

		&__content-wrapper {
			background-color: var(--multiselect-bg) !important;
			border: 1px solid var(--multiselect-border) !important;
			border-radius: 0.25rem;
			margin: 0.3rem 0;
		}

		&__content {
			border-color: transparent !important;
		}
	}
}
</style>
