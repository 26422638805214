<template>
	<div
		class="divider-play"
		:style="style"
	></div>
</template>

<script>
export default {
	name: "DividerPlay",
	props: {
		h: [String, Number],
		height: [String, Number],
		w: [String, Number],
		width: [String, Number],
		marginY: [String, Number],
		marginX: [String, Number],
		margin: [String, Number],
		marginLeft: [String, Number],
		marginRight: [String, Number],
		marginTop: [String, Number],
		marginBottom: [String, Number],
		my: [String, Number],
		mx: [String, Number],
		m: [String, Number],
		ml: [String, Number],
		mr: [String, Number],
		mt: [String, Number],
		mb: [String, Number],
	},
	computed: {
		style() {
			const styles = {
				height: this.height ?? this.h,
				width: this.width ?? this.w,
				margin: this.margin ?? this.m,
				marginLeft: this.marginLeft ?? this.ml ?? this.marginX ?? this.mx,
				marginRight: this.marginRight ?? this.mr ?? this.marginX ?? this.mx,
				marginTop: this.marginTop ?? this.mt ?? this.marginY ?? this.my,
				marginBottom: this.marginBottom ?? this.mb ?? this.marginY ?? this.my,
			};

			// Remove undefined properties
			Object.keys(styles).forEach(key => {
				if (styles[key] === undefined) {
					delete styles[key];
				}
			});

			return styles;
		},
	},
};
</script>

<style lang="scss">
.divider-play {
	width: 100%;
	min-height: 1px;
	background-color: var(--divider-color);
}
</style>
