var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.link ? 'router-link' : 'button',{tag:"element",class:[
		'custom-button',
		_vm.size,
		_vm.type,
		_vm.rounded ? 'rounded' : '',
		_vm.fullWidth ? 'fullWidth' : '',
		{ disabled: _vm.disabled },
		{ 'skeleton skeleton-dark skeleton-button': _vm.loading },
		_vm.$vnode.data.staticClass,
	],style:(_vm.computedStyle),attrs:{"to":_vm.link,"data-testid":"button-play","disabled":_vm.disabled},on:{"click":_vm.emitClick}},[(_vm.icon)?_c('IconSax',{attrs:{"name":_vm.icon,"type":_vm.iconType}}):_vm._e(),_vm._t("default",function(){return [_c('TextPlay',{attrs:{"size":_vm.fontSize,"weight":_vm.fontWeight,"text":_vm.text}})]}),(_vm.iconRight)?_c('IconSax',{attrs:{"name":_vm.iconRight,"type":_vm.iconType}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }